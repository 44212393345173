<template>
  <Auth>
    <h1 class="title title--2">Réinitialiser votre mot de passe</h1>
    <form @submit.prevent="resetPassword" class="connection-form">
      <div class="form-row">
        <input
          type="password"
          class="input"
          placeholder="Votre mot de passe"
          v-model="password"
        />
      </div>
      <div class="form-row">
        <input
          type="password"
          class="input"
          placeholder="Confirmation"
          v-model="passwordConfirmation"
        />
      </div>
      <div v-if="$v.password.required && $v.password.$invalid">
        <p class="text text--not-available">
          <img src="@/assets/svg/Bad-Check.svg" alt="" />
          <br />
          Votre mot de passe doit contenir un minimum de huit caractères ainsi qu'une
          majuscule et un caractère spécial
        </p>
      </div>
      <div
        v-if="$v.passwordConfirmation.required && $v.passwordConfirmation.$invalid"
      >
        <p class="text text--not-available">
          <img src="@/assets/svg/Bad-Check.svg" alt="" />
          <br />
          Les deux mots de passe doivent être identiques
        </p>
      </div>
      <button :class="['button', { 'button--invalid': $v.$invalid }]" type="submit">
        ENREGISTRER
      </button>
    </form>
    <p>
      <router-link class="link link--cancel" :to="{ name: 'Connection' }">
        Annuler
      </router-link>
    </p>
  </Auth>
</template>

<script>
import { sameAs, required, minLength } from 'vuelidate/lib/validators';
import { api } from '@/lib/api';
import { Auth } from '@/components';

export default {
  components: { Auth },

  data() {
    return {
      password: '',
      passwordConfirmation: '',
    };
  },

  methods: {
    async resetPassword() {
      if (this.$v.$invalid) {
        return;
      }

      try {
        const token = this.$route.query.token;
        await api.put(`/password_reset_tokens/${token}`, {
          newPassword: this.password,
        });
        this.$router.push({ name: 'Connection' });
      } catch {
        this.$router.push({ name: 'ForgotPassword' });
      }
    },
  },

  validations: {
    password: {
      required,
      minLength: minLength(8),
      goodPassword: password => {
        return /\W+/.test(password) && /[A-Z]+/.test(password);
      },
    },
    passwordConfirmation: {
      sameAs: sameAs('password'),
      required,
    },
  },
};
</script>
