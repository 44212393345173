<template>
  <div class="settings-block">
    <div class="settings-block__settings">
      <ImageInput
        upload-label="Importer une photo de profil"
        delete-label="Supprimer la photo de profil"
        :image-url="coworker.profilePicture"
        @upload="uploadImage"
        @remove="removeProfilePicture"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import { ImageInput } from '@/components/common/form';

export default {
  components: { ImageInput },

  props: {
    coworker: Object,
    index: Number,
  },

  computed: {
    ...mapGetters({
      getSiteVersionCoworkers: 'siteVersion/getCoworkers',
    }),
  },

  methods: {
    ...mapActions({
      updateSiteVersionCoworker: 'siteVersion/updateCoworker',
    }),

    openFileExplorer() {
      this.$refs.fileInput.click();
    },

    async uploadImage(url) {
      const index = this.index;
      this.updateSiteVersionCoworker({ index, profilePicture: url });
      this.coworker.profilePicture = url;
    },

    removeProfilePicture() {
      const index = this.index;
      this.updateSiteVersionCoworker({ index, profilePicture: '' });
      this.coworker.profilePicture = '';
    },
  },

  validations: {
    coworker: {
      required,
    },

    index: required,
  },
};
</script>
