<template>
  <div class="step">
    <h1 class="title--1 title--little-spaced">
      Quels sont les contacts <br />
      principaux de votre cabinet ?
    </h1>
    <p class="text text--hint text--with-margin-bottom">
      Votre numéro de téléphone et votre adresse mail seront indiqués sur votre site
      pour que les <br />internautes puissent vous contacter. Vous pourrez modifier
      ces informations depuis <br />
      votre espace « Gestion de mon site ». A noter : Lorsqu’un internaute vous
      contacte <br />
      via le formulaire de contact de votre site, vous recevrez automatiquement
      <br />
      son message sur l’adresse mail que vous avez renseignée. Vous pourrez également
      <br />
      retrouvez son message depuis votre espace personnel Solency.
      <br />
      De son côté, l’internaute recevra une confirmation
      <br />
      d’envoi de son message par email.
    </p>
    <div class="form-row">
      <input
        type="text"
        class="input input--medium"
        placeholder="Numéro de téléphone"
        v-model="officePhoneNumber"
      />
      <input
        type="text"
        class="input input--medium"
        placeholder="Contact email principal"
        v-model="officeEmail"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import {
  required,
  email,
  maxLength,
  minLength,
  numeric,
} from 'vuelidate/lib/validators';

export default {
  data() {
    return {
      officePhoneNumber: '',
      officeEmail: '',
    };
  },

  created() {
    this.officePhoneNumber = this.getOfficeAddress.phoneNumber;
    this.officeEmail = this.getOfficeEmail;
  },

  mounted() {
    this.$v.$touch();
    this.$emit('validate', this.$v.$invalid);
  },

  watch: {
    '$v.$invalid'(invalid) {
      this.$emit('validate', invalid);
    },

    officePhoneNumber(value) {
      if (!this.$v.officePhoneNumber.$invalid) {
        this.updateOfficeAddress({ phoneNumber: value });
      }
    },

    officeEmail(value) {
      if (!this.$v.officeEmail.$invalid) {
        this.updateOfficeEmail(value);
      }
    },
  },

  computed: {
    ...mapGetters({
      getOfficeAddress: 'office/getAddress',
      getOfficeEmail: 'office/getEmail',
    }),
  },

  methods: {
    ...mapActions({
      updateOfficeAddress: 'office/updateAddress',
      updateOfficeEmail: 'office/updateEmail',
    }),
  },

  validations: {
    officePhoneNumber: {
      required,
      numeric,
      maxLength: maxLength(10),
      minLength: minLength(10),
    },

    officeEmail: {
      required,
      email,
    },
  },
};
</script>
