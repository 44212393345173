<template>
  <div class="stats">
    <h1 class="stats__title">
      Les performances de votre site
    </h1>
    <p class="text text--hint stats__intro">
      Suivez ici les performances de votre site web (30 derniers jours)
    </p>
    <div v-if="!isEmpty(stats)" class="stats__cards">
      <div class="stats__card stat-card">
        <h4>
          Nombre de messages reçus
        </h4>
        <p class="text text--hint">
          Cette donnée correspond au nombre de messages qui ont été laissés par les
          internautes depuis les 30 derniers jours. N’hésitez pas à les contacter
          pour convertir vos prospects en clients !
        </p>
        <p class="stats__card__data">
          {{ stats.messages }}
        </p>
        <p class="text text--hint">En date du {{ currentDate }}</p>
      </div>
      <div class="stats__card stat-card">
        <h4>
          Nombre de visiteurs
        </h4>
        <p class="text text--hint">
          Votre nombre de visite peut varier, il est surtout important de regarder
          l’évolution d’un mois à l’autre. N’hésitez pas à prendre note de cette
          performance pour la comparer !
        </p>
        <p class="stats__card__data">
          {{ stats.visits }}
        </p>
        <p class="text text--hint">
          En moyenne par jour
        </p>
      </div>
      <div class="stats__card stat-card">
        <h4>
          Durée de session
        </h4>
        <p class="text text--hint">
          Cette donnée correspond au temps moyen passé par un internaute sur votre
          site. Nous estimons une bonne moyenne autour d’une minute.
        </p>
        <p class="stats__card__data stats__card__data--small">
          {{ formattedSessionTime }}
        </p>
        <p class="text text--hint">
          En moyenne par visite
        </p>
      </div>
      <div class="stats__card stat-card">
        <h4>
          Devices utilisés
        </h4>
        <p class="text text--hint">
          Ces données sont utiles pour comprendre le comportement de vos internautes.
          La tendance est de plus en plus portée sur le mobile mais l’expérience est
          aussi fluide sur ordinateur que sur smartphone !
        </p>
        <canvas v-if="stats.devices.length > 0" ref="canvas" />
        <p v-else class="text text--instruction">
          Aucune donnée disponible
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { isEmpty } from 'lodash';
import 'chartjs-plugin-datalabels';
import Chart from 'chart.js';

export default {
  props: {
    stats: Object,
  },

  data() {
    return {
      chartOptions: {
        aspectRatio: 1,
        legend: {
          position: 'bottom',
          align: 'start',
          labels: {
            usePointStyle: true,
          },
        },
        plugins: {
          datalabels: {
            formatter: (value, canvasContext) => {
              const reducer = (nextValue, currentValue) => nextValue + currentValue;
              const { data } = canvasContext.chart.data.datasets[0];

              return ((value * 100) / data.reduce(reducer)).toFixed(0) + '%';
            },
            color: '#fff',
            font: {
              family: 'Montserrat',
              size: 13,
              weight: 'bold',
            },
          },
        },
      },

      chart: null,
    };
  },

  mounted() {
    this.createChart();
  },

  updated() {
    this.createChart();
  },

  computed: {
    formattedSessionTime() {
      const minutes = Math.floor(this.stats.sessionTime / 60);
      const seconds = this.stats.sessionTime % 60;

      return `${minutes}min${seconds}`;
    },

    currentDate() {
      return new Date().toLocaleDateString('fr-FR', {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
      });
    },

    chartData() {
      return {
        labels: this.chartLabels,
        datasets: this.chartDataSet,
      };
    },

    chartLabels() {
      return this.stats.devices.map(device => device.label);
    },

    chartDataSet() {
      const colors = ['#31B39F', '#EBBA03', '#FB4D3D'];

      return [
        {
          data: this.stats.devices.map(device => device.nb_visits),
          backgroundColor: colors,
        },
      ];
    },
  },

  methods: {
    isEmpty,
    createChart() {
      if (this.$refs.canvas) {
        this.chart = new Chart(this.$refs.canvas.getContext('2d'), {
          type: 'pie',
          data: this.chartData,
          options: this.chartOptions,
        });
      }
    },
  },
};
</script>
