<template>
  <div class="global-settings">
    <h1 class="title--1 title--unspaced">
      Paramètres de votre site
    </h1>

    <div class="global-settings__block">
      <AddressAndName />
    </div>

    <div class="global-settings__block">
      <Contact />
    </div>

    <div class="global-settings__block">
      <DescriptionAndFees />
    </div>

    <div class="global-settings__block">
      <Logo />
    </div>

    <div class="global-settings__block">
      <SocialNetworks />
    </div>

    <div class="global-settings__block">
      <Coworkers />
    </div>
  </div>
</template>

<script>
import {
  AddressAndName,
  Contact,
  Logo,
  SocialNetworks,
  Coworkers,
  DescriptionAndFees,
} from '../SettingsBlocks';

export default {
  components: {
    AddressAndName,
    Contact,
    Logo,
    SocialNetworks,
    Coworkers,
    DescriptionAndFees,
  },
};
</script>
