import Vue from 'vue';
import VueRouter from 'vue-router';

import SiteCreationForm from '@/views/SiteCreationForm/SiteCreationForm';
import {
  StepZero,
  StepOfficeName,
  StepAddress,
  StepContact,
  StepSpecialities,
  StepDescription,
  StepSocialNetworks,
  StepCoWorkers,
  StepTemplateChoice,
  StepCustomization,
  StepLoading,
  StepFinale,
} from '@/views/SiteCreationForm/Steps';

import { Dashboard, Home, SiteSettings, Stats, Mailbox } from '@/views/Dashboard';

import {
  Connection,
  Register,
  ForgotPassword,
  ResetPassword,
  Welcome,
} from '@/views/Authentication';

import store from '@/store';

Vue.use(VueRouter);

const routes = [
  {
    path: '',
    name: 'Landing',
  },
  {
    path: '/connexion',
    name: 'Connection',
    component: Connection,
  },
  {
    path: '/inscription',
    name: 'Register',
    component: Register,
  },
  {
    path: '/mot-de-passe-perdu',
    name: 'ForgotPassword',
    component: ForgotPassword,
  },
  {
    path: '/changement-mot-de-passe',
    name: 'ResetPassword',
    component: ResetPassword,
  },
  {
    path: '/verification-email',
    name: 'EmailCheckIn',
    component: () => import('@/views/Authentication/EmailCheckIn'),
  },
  {
    path: '/verifier-email',
    name: 'VerifyEmail',
    component: () => import('@/views/Authentication/VerifyEmail'),
  },
  {
    path: '/bienvenue',
    name: 'Welcome',
    component: Welcome,
  },
  {
    path: '/creation-site/',
    name: 'SiteCreation',
    component: SiteCreationForm,
    children: [
      {
        path: '0',
        name: 'StepZero',
        component: StepZero,
      },
      {
        path: '1',
        name: 'StepOfficeName',
        component: StepOfficeName,
      },
      {
        path: '2',
        name: 'StepAddress',
        component: StepAddress,
      },
      {
        path: '3',
        name: 'StepContact',
        component: StepContact,
      },
      {
        path: '4',
        name: 'StepSpecialities',
        component: StepSpecialities,
      },
      {
        path: '5',
        name: 'StepDescription',
        component: StepDescription,
      },
      {
        path: '6',
        name: 'StepSocialNetworks',
        component: StepSocialNetworks,
      },
      {
        path: '7',
        name: 'StepCoWorkers',
        component: StepCoWorkers,
      },
      {
        path: '8',
        name: 'StepTemplateChoice',
        component: StepTemplateChoice,
      },
      {
        path: '9',
        name: 'StepCustomization',
        component: StepCustomization,
      },
      {
        path: '10',
        name: 'StepDomainName',
        component: () => import('@/views/SiteCreationForm/Steps/StepDomainName'),
      },
      {
        path: '11',
        name: 'StepLoading',
        component: StepLoading,
      },
      {
        path: '12',
        name: 'StepFinale',
        component: StepFinale,
      },
    ],
  },
  {
    path: '/dashboard/',
    name: 'Dashboard',
    component: Dashboard,
    children: [
      {
        path: '',
        name: 'DashboardHome',
        component: Home,
      },
      {
        path: 'site-settings',
        name: 'SiteSettings',
        component: SiteSettings,
      },
      {
        path: 'stats',
        name: 'Stats',
        component: Stats,
      },
      {
        path: 'messagerie',
        name: 'Mailbox',
        component: Mailbox,
      },
    ],
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  const anonymousRoutes = [
    'Connection',
    'Register',
    'ForgotPassword',
    'ResetPassword',
  ];

  if (!anonymousRoutes.includes(to.name) && !store.getters['auth/isTokenValid']) {
    return next({ name: 'Connection', query: { redirectTo: to.fullPath } });
  }

  const emailVerificationRoutes = ['VerifyEmail', 'EmailCheckIn'];

  if (
    !store.getters['auth/isActive'] &&
    !emailVerificationRoutes.includes(to.name) &&
    !anonymousRoutes.includes(to.name)
  ) {
    return next({ name: 'VerifyEmail' });
  }

  if (!store.getters['user/isLogged']) {
    await store.dispatch('user/login', store.getters['auth/getToken']);
  }

  if (to.name === 'Landing') {
    if (to.query.token) {
      await store.dispatch('site/updateToken', to.query.token);
    }

    return next({ name: 'DashboardHome' });
  }

  next();
});

export default router;
