import Vue from 'vue';
import App from './App.vue';
import './assets/style/main.scss';
import Vuelidate from 'vuelidate';
import router from './router';
import store from './store';

Vue.config.productionTip = false;
Vue.use(Vuelidate);

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app');
