<template>
  <Auth>
    <h1 class="title title--2">
      Pour commencer, créez votre <br />compte gratuitement
    </h1>
    <p class="text text--hint">
      Cela vous permettra d'accéder à votre plateforme personnelle !
    </p>

    <form @submit.prevent="register" class="register-form">
      <div class="form-row">
        <input type="text" class="input" placeholder="Votre nom" v-model="name" />
        <input
          type="text"
          class="input"
          placeholder="Votre prénom"
          v-model="firstname"
        />
      </div>
      <div class="form-row">
        <input
          type="email"
          class="input"
          placeholder="Votre email"
          v-model="email"
        />
      </div>
      <div class="form-row">
        <input
          type="email"
          class="input"
          placeholder="Confirmation de votre email"
          v-model="emailConfirmation"
        />
      </div>
      <div class="form-row">
        <input
          type="password"
          class="input"
          placeholder="Votre mot de passe"
          v-model="password"
        />
        <input
          type="password"
          class="input"
          placeholder="Confirmation"
          v-model="passwordConfirmation"
        />
      </div>
      <div v-if="$v.email.required && $v.email.$invalid">
        <p class="text text--not-available">
          <img src="@/assets/svg/Bad-Check.svg" alt="" />
          <br />
          Vérifiez l'authenticité de votre adresse mail
        </p>
      </div>
      <div v-if="$v.emailConfirmation.required && $v.emailConfirmation.$invalid">
        <p class="text text--not-available">
          <img src="@/assets/svg/Bad-Check.svg" alt="" />
          <br />
          Les deux adresses mails sont différentes
        </p>
      </div>
      <div v-if="$v.password.required && $v.password.$invalid">
        <p class="text text--not-available">
          <img src="@/assets/svg/Bad-Check.svg" alt="" />
          <br />
          Votre mot de passe doit contenir un minimum de huit caractères ainsi qu'une
          majuscule et un caractère spécial
        </p>
      </div>
      <div
        v-if="$v.passwordConfirmation.required && $v.passwordConfirmation.$invalid"
      >
        <p class="text text--not-available">
          <img src="@/assets/svg/Bad-Check.svg" alt="" />
          <br />
          Les deux mots de passe doivent être identiques
        </p>
      </div>
      <div v-if="emailAvaibility === false">
        <p class="text text--not-available">
          <img src="@/assets/svg/Bad-Check.svg" alt="" />
          <br />
          Cette adresse mail est déjà utilisée
        </p>
      </div>
      <div v-if="error === true">
        <p class="text text--not-available">
          <img src="@/assets/svg/Bad-Check.svg" alt="" />
          <br />
          Une erreur est survenue, contactez un administrateur
        </p>
      </div>
      <button :class="['button', { 'button--invalid': $v.$invalid }]" type="submit">
        S'INSCRIRE
      </button>
    </form>
  </Auth>
</template>

<script>
import { Auth } from '@/components';
import { sameAs, email, required, minLength } from 'vuelidate/lib/validators';
import { api } from '@/lib/api';
import { mapActions } from 'vuex';

export default {
  components: { Auth },

  data() {
    return {
      name: '',
      firstname: '',
      email: '',
      emailConfirmation: '',
      password: '',
      passwordConfirmation: '',
      emailAvaibility: null,
      error: false,
    };
  },

  watch: {
    email() {
      this.emailAvaibility = null;
    },

    emailConfirmation() {
      this.emailAvaibility = null;
    },
  },

  methods: {
    ...mapActions({
      updateAuth: 'auth/updateAuth',
    }),

    async register() {
      if (this.$v.$invalid) {
        return;
      }

      try {
        const response = await api.post('/users', {
          email: this.email,
          firstname: this.firstname,
          lastname: this.name,
          plainPassword: this.password,
        });

        this.emailAvaibility = response.status === 201;
      } catch {
        return (this.emailAvaibility = false);
      }

      try {
        const {
          data: { token },
        } = await api.post('/auth_tokens', {
          email: this.email,
          password: this.password,
        });

        await this.updateAuth(token);

        await api.post('/email_confirmation_tokens', {});

        this.$router.push({ name: 'VerifyEmail' });
      } catch {
        this.error = true;
      }
    },
  },

  validations: {
    name: {
      required,
    },
    firstname: {
      required,
    },
    email: {
      email,
      required,
    },
    emailConfirmation: {
      sameAs: sameAs('email'),
      required,
    },
    password: {
      required,
      minLength: minLength(8),
      goodPassword: password => {
        return /\W+/.test(password) && /[A-Z]+/.test(password);
      },
    },
    passwordConfirmation: {
      sameAs: sameAs('password'),
      required,
    },
  },
};
</script>
