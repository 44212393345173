<template>
  <div class="live-edit">
    <iframe
      v-if="!isInternetExplorer"
      scrolling="no"
      ref="iframe"
      class="live-edit__iframe"
      frameborder="0"
      :style="{ width: previewSize }"
    >
    </iframe>
    <div v-else class="text text--center">
      <p class="text--large">
        Votre navigateur n'est pas supporté par notre plateforme car il n'est plus à
        jour.
      </p>
      <p>
        Pour utiliser cette fonctionnalité, merci d'utiliser un navigateur moderne
        tel que Chrome, Firefox, Safari ou Edge.
      </p>
    </div>

    <ImageModal
      v-if="showImageUploadModal"
      @cancel="closeModal"
      @confirm="handleConfirm"
    />

    <Modal class="live-edit__modal" v-show="showBravoModal" :icon="true">
      <template v-slot:body>
        <p class="title--2 title--bold title--unspaced">BRAVO !</p>
        <p class="title--3 title--unspaced">Voici votre site.</p>
        <p class="text text--with-margin-bottom">
          Vous pouvez encore le peaufiner avant de le mettre en ligne.
        </p>
        <button class="button button--x-small" @click="showBravoModal = false">
          <span>CONTINUER</span>
        </button>
      </template>
    </Modal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import axios from 'axios';
import { ImageModal } from '@/components';
import Modal from '../../../../components/Modal';

export default {
  components: { Modal, ImageModal },
  data() {
    return {
      green: '#78D978',
      iframe: null,
      currentlyEditedElementId: null,
      currentlyEditedImage: null,
      showImageUploadModal: false,
      showBravoModal: true,
    };
  },

  props: {
    previewSize: String,
  },

  watch: {
    previewSize() {
      this.iframe.style.height =
        this.iframe.contentWindow.document.documentElement.scrollHeight + 'px';
    },
    async getSiteHostingSettings() {
      await this.refreshIframe();
    },
  },

  async mounted() {
    this.iframe = this.$refs.iframe;

    await this.refreshIframe();
  },

  computed: {
    ...mapGetters({
      getSiteVersionSite: 'siteVersion/getSite',
      getSiteVersionContent: 'siteVersion/getContent',
      getSiteVersionStatus: 'siteVersion/getStatus',
      getSiteHostingSettings: 'site/getHostingSettings',
    }),
    isInternetExplorer() {
      const userAgent = window.navigator.userAgent;
      const msie = userAgent.indexOf('MSIE ');
      return msie > 0 || !!navigator.userAgent.match(/Trident.*rv:11\./);
    },
  },

  methods: {
    ...mapActions({
      updateSiteVersionContentElementById: 'siteVersion/updateContentElementById',
    }),

    processContentForLiveEdit() {
      this.formatIframeForDisplay(this.iframe);

      const links = this.iframe.contentWindow.document.querySelectorAll('[href]');
      Object.keys(links).forEach(el => {
        Object.assign(links[el].style, {
          pointerEvents: 'none',
        });
      });

      const matomoElements = this.iframe.contentWindow.document.querySelectorAll(
        '.matomo-script, #optout-form'
      );

      Object.keys(matomoElements).forEach(el => {
        matomoElements[el].parentNode.removeChild(matomoElements[el]);
      });

      this.getSiteVersionContent.forEach(element => {
        const domElement = this.iframe.contentWindow.document.getElementById(
          element.id
        );

        if (!domElement) {
          return;
        }

        this.makeElementEditable(domElement, element.type);

        switch (element.type) {
          case 'text':
            this.makeTextEditable(domElement);
            break;
          case 'image':
            this.makeImageEditable(domElement, element.id);
            break;
          default:
            break;
        }
      });
    },

    formatIframeForDisplay(iframe) {
      iframe.style.height =
        iframe.contentWindow.document.documentElement.scrollHeight + 'px';

      iframe.contentWindow.document.getElementById('App').style.minHeight = '0px';

      const link = document.createElement('link');
      link.href = 'https://fonts.googleapis.com/css2?family=Montserrat&display=swap';
      link.rel = 'stylesheet';

      iframe.contentWindow.document.head.appendChild(link);
    },

    makeTextEditable(domElement) {
      domElement.contentEditable = true;

      domElement.addEventListener('input', event => {
        this.updateSiteVersionContentElementById({
          id: domElement.id,
          type: 'text',
          content: event.target.textContent,
        });
      });
    },

    makeImageEditable(domElement, id) {
      if (domElement.tagName !== 'DIV') {
        domElement = domElement.parentNode;
      }

      const uploadSvg = require('@/assets/svg/upload.svg');
      const uploadLabel = document.createElement('span');
      const uploadIcon = document.createElement('img');
      uploadIcon.src = uploadSvg;

      uploadLabel.appendChild(uploadIcon);
      uploadLabel.addEventListener('click', () => {
        this.currentlyEditedElementId = id;
        this.currentlyEditedImage = domElement.querySelector('img');

        this.openModal();
      });

      Object.assign(uploadLabel.style, {
        position: 'absolute',
        top: '0',
        left: '0',
        margin: '10px 0 0 10px',
        padding: '5px',
        background: this.green,
        borderRadius: '5px',
        cursor: 'pointer',
      });

      domElement.appendChild(uploadLabel);
    },

    handleConfirm(url) {
      this.currentlyEditedImage.src = url;
      this.updateSiteVersionContentElementById({
        id: this.currentlyEditedElementId,
        content: url,
        type: 'image',
      });

      this.closeModal();
    },

    makeElementEditable(domElement, type) {
      let wrapper = domElement;

      wrapper = document.createElement('div');
      Object.assign(wrapper.style, {
        border: `1px dashed ${this.green}`,
        position: 'relative',
        margin: window.getComputedStyle(domElement).margin,
      });

      if (type !== 'image') {
        Object.assign(wrapper.style, {
          width: domElement.offsetWidth + 'px',
        });
      }

      Object.assign(domElement.style, {
        margin: '0',
      });

      Object.assign(domElement.style, {
        outline: 'none',
      });

      const editLabel = document.createElement('span');
      editLabel.textContent = 'ÉDITER';
      editLabel.contentEditable = false;

      Object.assign(editLabel.style, {
        position: 'absolute',
        top: '0',
        left: '0',
        transform: 'translate(-1px, -100%)',
        height: '21px',
        padding: '4px 12px',
        margin: '0',
        backgroundColor: this.green,
        color: 'white',
        fontFamily: 'Montserrat',
        fontWeight: 'bold',
        fontSize: '11px',
        lineHeight: '1.5',
      });

      domElement.parentNode.replaceChild(wrapper, domElement);
      wrapper.appendChild(domElement);

      wrapper.appendChild(editLabel);
    },

    async refreshIframe() {
      const siteVersionStatus = this.getSiteVersionStatus.toLowerCase();

      let { data: iframeContent } = await axios.get(
        this.getSiteHostingSettings[siteVersionStatus].url
      );

      iframeContent = iframeContent.replace(
        './',
        this.getSiteHostingSettings[siteVersionStatus].url + '/'
      );

      const iframeDocument = this.iframe.contentDocument;
      iframeDocument.open('text/html', 'replace');
      iframeDocument.write(iframeContent);
      iframeDocument.close();

      this.iframe.addEventListener('load', this.processContentForLiveEdit);
    },

    openModal() {
      this.showImageUploadModal = true;
    },

    closeModal() {
      this.showImageUploadModal = false;
    },
  },
};
</script>
