<template>
  <div class="mailbox">
    <h1>
      Ma messagerie
    </h1>

    <p class="text text--hint mailbox__intro">
      Vous retrouverez ici l'ensemble des messages qui ont été déposés <br />
      sur votre site web. N'hésitez pas à recontacter ces prospects <br />
      pour les convertir en clients !
    </p>

    <div class="mailbox__messages" v-if="messages.length">
      <div
        class="mailbox__message"
        v-for="(message, index) in messages"
        :key="index"
      >
        <div class="mailbox__message__header">
          <p class="text text--hint">
            {{ new Date(message.createdAt).toLocaleString() }}
          </p>
        </div>
        <div class="mailbox__message__body">
          <div class="mailbox__message__body__sender">
            <p class="mailbox__message__body__sender__name">
              {{ message.firstname }} {{ message.lastname }}
            </p>
            <a
              class="link mailbox__message__body__sender__email"
              :href="`mailto:${message.email}`"
            >
              {{ message.email }}
            </a>
          </div>
          <p class="mailbox__message__body__subject">
            {{ message.subject }}
          </p>
          <p class="mailbox__message__body__content">
            {{ message.content }}
          </p>
        </div>
      </div>
    </div>

    <p v-else class="text text--large text--center">Vous n'avez aucun message !</p>
  </div>
</template>

<script>
import { api } from '@/lib/api';

export default {
  props: {
    messages: Array,
    unreadMessages: Boolean,
  },

  mounted() {
    this.markMessagesAsRead();
  },

  updated() {
    this.markMessagesAsRead();
  },

  methods: {
    markMessagesAsRead() {
      if (this.unreadMessages) {
        this.$emit('mark-read');
        this.messages.forEach(message => {
          if (!message.read) {
            api.put(message['@id'], {
              read: true,
            });
          }
        });
      }
    },
  },
};
</script>
