var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"site-settings"},[_c('div',{staticClass:"site-settings__header"},[_c('div',{staticClass:"site-settings__header__tabs"},[_c('a',{class:[
          'site-settings__header__tabs__tab',
          {
            'site-settings__header__tabs__tab--active':
              _vm.currentTab === 'GlobalSettings',
          } ],on:{"click":function($event){_vm.currentTab = _vm.isCurrentStepIndexGreaterThan('GlobalSettings')
            ? 'GlobalSettings'
            : _vm.currentTab}}},[_vm._v(" Étape 1 ")]),_c('a',{class:[
          'site-settings__header__tabs__tab',
          {
            'site-settings__header__tabs__tab--active':
              _vm.currentTab === 'DesignSettings',
          } ],on:{"click":function($event){_vm.currentTab = _vm.isCurrentStepIndexGreaterThan('DesignSettings')
            ? 'DesignSettings'
            : _vm.currentTab}}},[_vm._v(" Étape 2 ")]),_c('a',{class:[
          'site-settings__header__tabs__tab',
          { 'site-settings__header__tabs__tab--active': _vm.currentTab === 'Legals' } ],on:{"click":function($event){_vm.currentTab = _vm.isCurrentStepIndexGreaterThan('Legals')
            ? 'Legals'
            : _vm.currentTab}}},[_vm._v(" Étape 3 ")]),_c('a',{class:[
          'site-settings__header__tabs__tab',
          {
            'site-settings__header__tabs__tab--active': _vm.currentTab === 'LiveEdit',
          } ],on:{"click":function($event){_vm.currentTab = _vm.isCurrentStepIndexGreaterThan('LiveEdit')
            ? 'LiveEdit'
            : _vm.currentTab}}},[_c('img',{staticClass:"site-settings__header__tabs__tab__icon",attrs:{"src":require("@/assets/svg/live-edit.svg"),"alt":""}}),_vm._v(" Prévisualisation ")])]),_c('div',{staticClass:"site-settings__header__cta"},[(_vm.displayLiveEditCtas)?_c('div',{staticClass:"site-settings__header__cta__icons"},[_c('img',{class:[
            'site-settings__header__cta__icon',
            {
              'site-settings__header__cta__icon--active':
                _vm.previewSize === _vm.previewSizeDesktop,
            } ],attrs:{"src":require("@/assets/svg/preview-desktop.svg"),"alt":""},on:{"click":function($event){_vm.previewSize = _vm.previewSizeDesktop}}}),_c('img',{class:[
            'site-settings__header__cta__icon',
            {
              'site-settings__header__cta__icon--active':
                _vm.previewSize === _vm.previewSizeTablet,
            } ],attrs:{"src":require("@/assets/svg/preview-tablet.svg"),"alt":""},on:{"click":function($event){_vm.previewSize = _vm.previewSizeTablet}}}),_c('img',{class:[
            'site-settings__header__cta__icon',
            {
              'site-settings__header__cta__icon--active':
                _vm.previewSize === _vm.previewSizeMobile,
            } ],attrs:{"src":require("@/assets/svg/preview-mobile.svg"),"alt":""},on:{"click":function($event){_vm.previewSize = _vm.previewSizeMobile}}})]):_vm._e(),_c('button',{directives:[{name:"show",rawName:"v-show",value:(_vm.currentTab === 'LiveEdit'),expression:"currentTab === 'LiveEdit'"}],staticClass:"site-settings__header__cta__button button button--negative button--x-small",on:{"click":_vm.save}},[_vm._v(" ENREGISTRER ")]),_c('button',{directives:[{name:"show",rawName:"v-show",value:(_vm.currentTab === 'LiveEdit'),expression:"currentTab === 'LiveEdit'"}],staticClass:"site-settings__header__cta__button button button--x-small",on:{"click":_vm.publish}},[_vm._v(" PUBLIER ")])])]),_c('div',{staticClass:"site-settings__body"},[(_vm.currentTab === 'GlobalSettings')?_c('GlobalSettings'):_vm._e(),(_vm.currentTab === 'DesignSettings')?_c('DesignSettings'):_vm._e(),(_vm.currentTab === 'Legals')?_c('Legals'):_vm._e(),(_vm.currentTab === 'LiveEdit')?_c('LiveEdit',{attrs:{"preview-size":_vm.previewSize}}):_vm._e(),_c('div',{staticClass:"site-settings__step-buttons-wrapper"},[_c('button',{directives:[{name:"show",rawName:"v-show",value:(
          ['GlobalSettings', 'DesignSettings', 'Legals'].includes(_vm.currentTab)
        ),expression:"\n          ['GlobalSettings', 'DesignSettings', 'Legals'].includes(currentTab)\n        "}],staticClass:"site-settings__next-step-button button button--icon button--x-small",on:{"click":_vm.nextStep}},[_c('span',[_vm._v("Étape suivante")])]),_c('a',{directives:[{name:"show",rawName:"v-show",value:(['DesignSettings', 'Legals'].includes(_vm.currentTab)),expression:"['DesignSettings', 'Legals'].includes(currentTab)"}],staticClass:"site-settings__previous-step-button link",on:{"click":_vm.previousStep}},[_c('span',[_vm._v("Retour à l'étape précédente")])])])],1),_vm._m(0),_c('transition',{attrs:{"name":"modal"}},[(_vm.showSaveModal)?_c('Modal',{attrs:{"icon":""},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('h3',[_vm._v("Votre site est enregistré !")])]},proxy:true},{key:"body",fn:function(){return [_c('p',{staticClass:"text text--hint"},[_vm._v(" Mais attention il n’est pas encore en ligne. Pour le mettre en ligne, veuillez cliquer sur Publier. ")])]},proxy:true}],null,false,2418324392)}):_vm._e()],1),_c('transition',{attrs:{"name":"modal"}},[(_vm.showPublishModal)?_c('Modal',{attrs:{"icon":""},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('h3',[_vm._v("Votre site a été mis à jour")])]},proxy:true}],null,false,3422701855)}):_vm._e()],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"site-settings__mobile-warning"},[_c('h1',[_vm._v(" Cette fonctionnalité n'est pour le moment pas disponible sur mobile ")])])}]

export { render, staticRenderFns }