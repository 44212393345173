const initialState = () => {
  return {
    status: 'PROD',
    fontConfig: {},
    colorConfig: {
      primary: '#FFFFFF',
      secondary: '#FFFFFF',
    },
    template: '',
    description: '',
    fees: '',
    socialNetworks: [],
    coworkers: [
      {
        firstname: '',
        lastname: '',
        socialNetworks: [],
        barLaw: '',
        email: '',
        phoneNumber: '',
        speciality: '',
        profilePicture: '',
        description: '',
      },
    ],
    content: [],
    site: '',
    legalNotice: '',
  };
};

const getters = {
  getState: state => state,

  getSocialNetworkUrlByType: state => type => {
    const social = state.socialNetworks.filter(social => social.type === type)[0];
    return social ? social.url : '';
  },

  getSocialNetworks: state => state.socialNetworks,

  getCoworkers: state => state.coworkers,

  getDescription: state => state.description,

  getFees: state => state.fees,

  getColorConfig: state => state.colorConfig,

  getFontConfig: state => state.fontConfig,

  getSite: state => state.site,

  getTemplate: state => state.template,

  getContent: state => state.content,

  getStatus: state => state.status,

  getLegalNotice: state => state.legalNotice,
};

const mutations = {
  setSite(state, site) {
    state.site = site;
  },

  setTemplate(state, template) {
    state.template = template;
  },

  addSocialNetwork(state, socialNetwork) {
    state.socialNetworks.push(socialNetwork);
  },

  removeSocialNetwork(state, socialNetwork) {
    state.socialNetworks = state.socialNetworks.filter(
      social => social.type !== socialNetwork.type
    );
  },

  modifySocialNetwork(state, socialNetwork) {
    state.socialNetworks = [
      ...state.socialNetworks.filter(social => social.type !== socialNetwork.type),
      socialNetwork,
    ];
  },

  addCoworker(state) {
    state.coworkers.push({
      firstname: '',
      lastname: '',
      socialNetworks: [],
      barLaw: '',
      email: '',
      phoneNumber: '',
      speciality: '',
      profilePicture: '',
      description: '',
    });
  },

  removeCoworker(state, index) {
    state.coworkers.splice(index, 1);
  },

  modifyCoworker(state, coworker) {
    const { index, ...fields } = coworker;

    Object.assign(state.coworkers[index], fields);
  },

  addCoworkerSocialNetwork(state, socialNetwork) {
    const { index, ...social } = socialNetwork;

    state.coworkers[index].socialNetworks.push(social);
  },

  removeCoworkerSocialNetwork(state, socialNetwork) {
    const { index } = socialNetwork;

    state.coworkers[index].socialNetworks = state.coworkers[
      index
    ].socialNetworks.filter(social => social.type !== socialNetwork.type);
  },

  modifyCoworkerSocialNetwork(state, socialNetwork) {
    const { index, ...social } = socialNetwork;

    state.coworkers[index].socialNetworks = [
      ...state.coworkers[index].socialNetworks.filter(s => s.type !== social.type),
      social,
    ];
  },

  setDescription(state, description) {
    state.description = description;
  },

  setFees(state, fees) {
    state.fees = fees;
  },

  setColorConfig(state, colorConfig) {
    state.colorConfig = colorConfig;
  },

  setFontConfig(state, fontConfig) {
    state.fontConfig = fontConfig;
  },

  setContent(state, content) {
    state.content = content;
  },

  setSocialNetworks(state, socialNetworks) {
    state.socialNetworks = socialNetworks;
  },

  setCoworkers(state, coworkers) {
    state.coworkers = coworkers;
  },

  setContentElementById(state, element) {
    state.content = [...state.content.filter(el => el.id !== element.id), element];
  },

  setStatus(state, status) {
    state.status = status;
  },

  setLegalNotice(state, legalNotice) {
    state.legalNotice = legalNotice;
  },
};

const actions = {
  updateSite({ commit }, site) {
    commit('setSite', site);
  },

  updateTemplate({ commit }, template) {
    commit('setTemplate', template);
  },

  updateSocialNetworks({ commit, state }, socialNetwork) {
    const { type, url } = socialNetwork;
    const social = {
      type,
      url,
    };

    if (!url) {
      commit('removeSocialNetwork', social);
      return;
    }

    if (state.socialNetworks.some(s => s.type === type)) {
      commit('modifySocialNetwork', social);
      return;
    }

    commit('addSocialNetwork', social);
  },

  addCoworker({ commit }) {
    commit('addCoworker');
  },

  removeCoworker({ commit }, coworker) {
    commit('removeCoworker', coworker);
  },

  updateCoworker({ commit }, coworker) {
    commit('modifyCoworker', coworker);
  },

  updateCoworkerSocialNetworks({ commit, state }, social) {
    if (!social.url) {
      commit('removeCoworkerSocialNetwork', social);
    }

    if (
      state.coworkers[social.index].socialNetworks.some(s => s.type === social.type)
    ) {
      commit('modifyCoworkerSocialNetwork', social);
      return;
    }

    commit('addCoworkerSocialNetwork', social);
  },

  updateDescription({ commit }, description) {
    commit('setDescription', description);
  },

  updateFees({ commit }, fees) {
    commit('setFees', fees);
  },

  updateColorConfig({ commit }, colorConfig) {
    commit('setColorConfig', colorConfig);
  },

  updateFontConfig({ commit }, fontConfig) {
    commit('setFontConfig', fontConfig);
  },

  updateAllFields({ commit }, siteVersion) {
    commit('setFontConfig', siteVersion.fontConfig);
    commit('setColorConfig', siteVersion.colorConfig);
    commit('setFees', siteVersion.fees);
    commit('setDescription', siteVersion.description);
    commit('setSite', siteVersion.site);
    commit('setTemplate', siteVersion.template['@id']);
    commit('setContent', siteVersion.content);
    commit('setStatus', siteVersion.status);
    commit('setLegalNotice', siteVersion.legalNotice);

    commit(
      'setSocialNetworks',
      siteVersion.socialNetworks.map(social => {
        const { type, url } = social;

        return {
          type,
          url,
        };
      })
    );

    commit(
      'setCoworkers',
      siteVersion.coworkers.map(coworker => {
        const {
          firstname,
          lastname,
          profilePicture,
          barLaw,
          email,
          phoneNumber,
          speciality,
          description,
        } = coworker;
        const socialNetworks = coworker.socialNetworks.map(social => {
          const { type, url } = social;

          return {
            type,
            url,
          };
        });

        return {
          firstname,
          lastname,
          profilePicture,
          barLaw,
          email,
          phoneNumber,
          speciality,
          description,
          socialNetworks,
        };
      })
    );
  },

  updateContentElementById({ commit }, element) {
    commit('setContentElementById', element);
  },

  updateStatus({ commit }, status) {
    commit('setStatus', status);
  },

  updateLegalNotice({ commit }, legalNotice) {
    commit('setLegalNotice', legalNotice);
  },
};

export default {
  namespaced: true,
  state: initialState(),
  getters,
  mutations,
  actions,
};
