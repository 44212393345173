<template>
  <div class="image-input">
    <div class="image-input__main">
      <div class="image-input__image" v-if="imageUrl">
        <img src="@/assets/svg/Check.svg" class="check-icon" alt="" />
        <img alt="" :src="imageUrl" class="image-input__image" ref="logoImg" />
      </div>
      <Modal v-else-if="showModal">
        <div slot="icon"></div>
        <div slot="body" class="image-input__croppie-container">
          <div ref="croppieContainer"></div>
        </div>
        <div slot="footer" class="image-input__footer">
          <div v-if="!$v.imageDimensions.dimensions">
            <p class="text text--not-available">
              <img src="@/assets/svg/Bad-Check.svg" alt="" />
              <br />
              Veuillez sélectionner un cadre d'au moins
              {{ imageWidthConstraint }} pixels de large par
              {{ imageHeightConstraint }} pixels de haut
            </p>
          </div>
          <div class="image-input__cta-container">
            <button
              class="button button--negative button--x-small"
              @click="closeModal()"
            >
              Annuler
            </button>
            <button class="button button--x-small" @click="uploadImage()">
              Valider
            </button>
          </div>
        </div>
      </Modal>
      <div class="image-input__input" @click="openFileExplorer" v-else>
        <input
          ref="fileInput"
          type="file"
          @change="openModal($event.target.files[0])"
        />

        <div class="image-input__input__content">
          <img src="@/assets/svg/upload.svg" alt="" />
          <p class="link">{{ uploadLabel }}</p>
        </div>
      </div>
    </div>
    <div v-if="!$v.image.fileSize">
      <p class="text text--not-available">
        <img src="@/assets/svg/Bad-Check.svg" alt="" />
        <br />
        Veuillez choisir une image de moins de 2mb
      </p>
    </div>
    <a class="link" @click="removeImage">{{ deleteLabel }}</a>
  </div>
</template>

<script>
import { Modal } from '@/components';
import { mapGetters, mapActions } from 'vuex';
import Croppie from 'croppie';
import { api } from '@/lib/api';

export default {
  components: { Modal },

  props: {
    uploadLabel: String,
    deleteLabel: String,
    imageUrl: String,
    imageWidthConstraint: {
      type: Number,
      default: 100,
    },
    imageHeightConstraint: {
      type: Number,
      default: 100,
    },
  },

  data() {
    return {
      showModal: false,
      croppie: null,
      image: {
        size: 0,
      },
      imageDimensions: {
        width: this.imageWidthConstraint,
        height: this.imageHeightConstraint,
      },
      imageName: '',
      croppieOptions: {
        enableResize: true,
      },
    };
  },

  beforeDestroy() {
    if (this.$refs.croppieContainer) {
      this.$refs.croppieContainer.removeEventListener(
        'update',
        this.onCroppieUpdate
      );
    }
  },

  computed: {
    ...mapGetters({
      getOfficeLogo: 'office/getLogo',
    }),
  },

  methods: {
    ...mapActions({
      updateOfficeLogo: 'office/updateLogo',
    }),

    openFileExplorer() {
      this.$refs.fileInput.click();
    },

    openModal(file) {
      this.image = file;

      if (this.$v.image.$invalid) {
        return;
      }

      this.showModal = true;
      this.imageName = file.name;

      this.$nextTick(() => this.readFile(file));
    },

    closeModal() {
      this.showModal = false;
    },

    readFile(file) {
      const reader = new FileReader();

      reader.addEventListener('load', () => {
        this.croppie = new Croppie(this.$refs.croppieContainer, this.croppieOptions);

        this.$refs.croppieContainer.addEventListener('update', this.onCroppieUpdate);

        this.croppie.bind({
          url: reader.result,
        });
      });

      reader.readAsDataURL(file);
    },

    onCroppieUpdate() {
      const viewport = document.querySelector('.cr-viewport');

      Object.assign(this.imageDimensions, {
        width: viewport.offsetWidth,
        height: viewport.offsetHeight,
      });
    },

    uploadImage() {
      this.croppie.result('blob').then(async file => {
        if (this.$v.imageDimensions.$invalid) {
          return;
        }

        const formData = new FormData();
        formData.append('file', file, this.imageName);

        const { data } = await api.post('/images', formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });

        this.$emit('upload', data.contentUrl);

        this.croppie.destroy();
        this.closeModal();
      });
    },

    removeImage() {
      this.$emit('remove');
      this.closeModal();
    },
  },

  validations() {
    return {
      image: {
        fileSize: image => {
          return image.size < 2000000;
        },
      },
      imageDimensions: {
        dimensions: imageDimensions => {
          return (
            imageDimensions.width >= this.imageWidthConstraint &&
            imageDimensions.height >= this.imageHeightConstraint
          );
        },
      },
    };
  },
};
</script>

<style scoped lang="scss">
@import '~croppie/croppie.css';
</style>
