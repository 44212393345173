<template>
  <div class="modal-mask">
    <div class="modal-wrapper">
      <div :class="['modal-container', `modal-container--${width}`]">
        <div v-if="icon" class="modal-icon">
          <img src="@/assets/svg/Check.svg" />
        </div>
        <div class="modal-header">
          <slot name="header"> </slot>
        </div>
        <div class="modal-body">
          <slot name="body"> </slot>
        </div>
        <div class="modal-footer">
          <slot name="footer"> </slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    width: {
      type: String,
      default: 'small',
    },
    icon: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
