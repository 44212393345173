<template>
  <div class="step step--wide step--big-spaced">
    <h1 class="title--1 title--little-spaced">
      Quels sont les domaines
      <br />
      d'intervention de votre cabinet ?
    </h1>

    <p class="text text--hint">
      Vous pouvez sélectionner jusqu’à 3 domaines d’intervention qui résultent d’une
      pratique professionnelle <br />
      effective et habituelle dans ce ou ces domaines correspondants.
    </p>
    <div class="specialities specialities--with-width">
      <div class="speciality" v-for="speciality in options" :key="speciality.id">
        <input
          :id="`speciality_${speciality.id}`"
          type="checkbox"
          class="speciality__checkbox"
          v-model="specialities"
          :value="speciality['@id']"
        />
        <label :for="`speciality_${speciality.id}`">
          <span>{{ speciality.name }}</span>
          <img src="@/assets/svg/Check.svg" class="speciality__checkmark" alt="" />
        </label>
      </div>
    </div>
  </div>
</template>

<script>
import { api } from '@/lib/api';
import { mapGetters, mapActions } from 'vuex';
import { required, maxLength } from 'vuelidate/lib/validators';

export default {
  data() {
    return {
      options: [],
      specialities: [],
    };
  },

  mounted() {
    this.$v.$touch();
    this.$emit('validate', this.$v.$invalid);
  },

  computed: {
    ...mapGetters({
      getOfficeSpecialities: 'office/getSpecialities',
    }),
  },

  async created() {
    const { data } = await api.get('/specialities');
    this.options = data['hydra:member'];
    this.specialities = this.getOfficeSpecialities;
  },

  methods: {
    ...mapActions({
      updateOfficeSpecialities: 'office/updateSpecialities',
    }),
  },

  watch: {
    '$v.$invalid'(invalid) {
      this.$emit('validate', invalid);
    },

    specialities(specialities) {
      if (!this.$v.specialities.$invalid) {
        this.updateOfficeSpecialities(specialities);
      }
    },
  },

  validations: {
    specialities: {
      required,
      maxLength: maxLength(6),
    },
  },
};
</script>
