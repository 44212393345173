const initialState = function() {
  return {
    uri: '',
    name: '',
    email: '',
    specialities: [],
    logo: '',
    address: {
      addressLine1: '',
      postalCode: '',
      city: '',
      phoneNumber: '',
      longitude: '',
      latitude: '',
    },
  };
};

const getters = {
  getState: state => state,
  getName: state => state.name,
  getEmail: state => state.email,
  getAddress: state => state.address,
  getSpecialities: state => state.specialities,
  getLogo: state => state.logo,
};

const mutations = {
  setName(state, value) {
    state.name = value;
  },
  setEmail(state, email) {
    state.email = email;
  },
  setAddress(state, addressField) {
    state.address = {
      ...state.address,
      ...addressField,
    };
  },
  setSpecialities(state, specialities) {
    state.specialities = specialities;
  },
  setLogo(state, logo) {
    state.logo = logo;
  },
  setUri(state, uri) {
    state.uri = uri;
  },
};

const actions = {
  updateName({ commit }, value) {
    commit('setName', value);
  },
  updateEmail({ commit }, email) {
    commit('setEmail', email);
  },
  updateAddress({ commit }, addressField) {
    commit('setAddress', addressField);
  },
  updateSpecialities({ commit }, specialities) {
    commit('setSpecialities', specialities);
  },
  updateLogo({ commit }, logo) {
    commit('setLogo', logo);
  },
  updateOffice({ commit }, office) {
    commit('setLogo', office.logo);
    commit('setSpecialities', office.specialities);
    commit('setAddress', office.address);
    commit('setEmail', office.email);
    commit('setName', office.name);
    commit('setUri', office['@id']);
  },
};

export default {
  namespaced: true,
  state: initialState(),
  getters,
  mutations,
  actions,
};
