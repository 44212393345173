<template>
  <div class="step step--big">
    <h1 class="title title--1">
      Très bien. <br />
      Maintenant, parlons un peu de votre équipe.
    </h1>
    <p>QUI SONT LES AVOCATS DE VOTRE CABINET ?</p>

    <div class="coworkers">
      <div
        class="form-row"
        v-for="(coworker, index) in getSiteVersionCoworkers"
        :key="index"
      >
        <input
          v-if="index === 0"
          type="text"
          class="input"
          placeholder="Nom"
          :value="user.lastname"
          disabled
        />
        <input
          v-if="index === 0"
          type="text"
          class="input"
          placeholder="Prénom"
          :value="user.firstname"
          disabled
        />
        <input
          v-if="index > 0"
          type="text"
          class="input"
          placeholder="Nom"
          :value="coworker.lastname"
          @input="
            updateSiteVersionCoworker({ index, lastname: $event.target.value })
          "
        />
        <input
          v-if="index > 0"
          type="text"
          class="input"
          placeholder="Prénom"
          :value="coworker.firstname"
          @input="
            updateSiteVersionCoworker({ index, firstname: $event.target.value })
          "
        />
        <img
          v-if="index > 0"
          src="@/assets/svg/Bad-Check.svg"
          class="icon-space"
          @click="removeSiteVersionCoworker(index)"
        />
      </div>
      <p class="link" @click="addSiteVersionCoworker">
        + Ajouter une personne supplémentaire
      </p>
    </div>
  </div>
</template>

<script>
import { api } from '@/lib/api';
import { mapGetters, mapActions } from 'vuex';

export default {
  data() {
    return {
      user: '',
    };
  },

  async created() {
    const { data: user } = await api.get(`/users/${this.getAuth}`);
    this.user = user;
    this.updateSiteVersionCoworker({
      index: 0,
      lastname: this.user.lastname,
      firstname: this.user.firstname,
    });
  },

  mounted() {
    this.$emit('validate', false);
  },

  computed: {
    ...mapGetters({
      getSiteVersionCoworkers: 'siteVersion/getCoworkers',
      getAuth: 'auth/getId',
    }),
  },

  methods: {
    ...mapActions({
      addSiteVersionCoworker: 'siteVersion/addCoworker',
      removeSiteVersionCoworker: 'siteVersion/removeCoworker',
      updateSiteVersionCoworker: 'siteVersion/updateCoworker',
    }),
  },
};
</script>
