<template>
  <div class="settings-block">
    <p class="text text--instruction">CHOISISSEZ UN COUPLE DE TYPOGRAPHIES</p>

    <div class="settings-block__settings">
      <GroupRadio @input="$emit('input', $event)">
        <GroupRadioInput
          v-for="(font, index) in fonts"
          :key="index"
          :value="font"
          :checked="isEqual(font, selectedFont)"
        >
          <RadioFont :primary="font.primary" :secondary="font.secondary">
            <img
              src="@/assets/svg/Check.svg"
              class="check-icon"
              alt=""
              v-if="isEqual(font, selectedFont)"
            />
          </RadioFont>
        </GroupRadioInput>
      </GroupRadio>
    </div>
  </div>
</template>

<script>
import { isEqual } from 'lodash';
import { GroupRadio, GroupRadioInput } from '@/components/common/form';
import { RadioFont } from '@/components';

export default {
  components: { GroupRadio, GroupRadioInput, RadioFont },

  props: {
    fonts: Array,
    selectedFont: Object,
  },

  methods: {
    isEqual,
  },
};
</script>
