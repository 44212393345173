<template>
  <Auth>
    <div v-if="!sent">
      <h1 class="title title--2">
        Vous avez oublié votre mot de passe ?
        <br />
        Créez-en un nouveau.
      </h1>
      <form @submit.prevent="forgotPassword" class="connection-form">
        <div class="form-row">
          <input
            type="email"
            class="input"
            placeholder="Votre email"
            v-model="email"
          />
        </div>
        <div v-if="sent === false">
          <p class="text text--not-available">
            <img src="@/assets/svg/Bad-Check.svg" alt="" />
            <br />
            Votre adresse mail n'existe pas
          </p>
        </div>
        <button class="button connection-form__cta" type="submit">
          RÉINITIALISER LE MOT DE PASSE
        </button>
      </form>
      <p>
        <router-link class="link link--cancel" :to="{ name: 'Connection' }">
          Annuler
        </router-link>
      </p>
    </div>

    <div v-else>
      <h1 class="title title--2">
        Merci. Vous allez recevoir un
        <br />
        lien pour changer votre mot de passe par email.
      </h1>
      <p>
        Vous n'avez rien reçu ?
        <a class="link link--nested" @click="resendForgotPassword()">
          Renvoyer cet email
        </a>
      </p>
    </div>
  </Auth>
</template>

<script>
import { api } from '@/lib/api';
import { Auth } from '@/components';

export default {
  components: { Auth },

  data() {
    return {
      email: '',
      sent: null,
    };
  },

  methods: {
    async forgotPassword() {
      try {
        const { status } = await api.post('/password_reset_tokens', {
          email: this.email,
        });
        this.sent = false;
        if (status === 204) {
          this.sent = true;
        }
      } catch {
        this.sent = false;
      }
    },
    resendForgotPassword() {
      this.forgotPassword();
    },
  },
};
</script>
