<template>
  <div class="setting-block">
    <div class="templates">
      <div v-for="template in templates" :key="template.id" class="template">
        <div :class="['template__image', { selected: selected(template['@id']) }]">
          <img
            src="@/assets/svg/Check.svg"
            class="check-icon check-icon--large"
            v-if="selected(template['@id'])"
          />
          <img
            :src="templateImageUrl(template.name)"
            alt=""
            class="template__image__img"
          />
          <div class="template__image__cta" v-if="!selected(template['@id'])">
            <button
              class="button button--negative button--small"
              @click="$emit('input', template['@id'])"
            >
              Choisir ce design
            </button>
          </div>
        </div>
        <a
          :href="previewUrl(template.name)"
          class="link template__preview"
          target="_blank"
          >Voir un aperçu</a
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    templates: Array,
    selectedTemplate: String,
  },

  methods: {
    previewUrl(templateName) {
      return `${process.env.VUE_APP_PREVIEW_URL}/${templateName}/dist/html/index.html`;
    },

    templateImageUrl(templateName) {
      return require(`@/assets/images/${templateName}.jpg`);
    },

    selected(templateId) {
      return templateId === this.selectedTemplate;
    },
  },
};
</script>
